<template>

  <div class="navbarManager">
    <div class="container content-end">
            <a v-if="locale=='ru'" class="manager-profile-button" @click="switchLocale('kz')"><span>RU</span></a>
            <a v-if="locale=='kz'" class="manager-profile-button" @click="switchLocale('ru')"><span>KZ</span></a>
      <button v-if="token" @click="signout" class="signout-btn" ><span class="signout-label">{{$t('navbar.signout')}}</span> <i class="signout-icon"></i></button>
    </div>


  </div>
</template>
<script>


export default {
  data() {
    return {
      token:'',
      locale:''
    }
  },

  mounted() {
    this.locale= this.$i18n.locale
    this.token=localStorage.getItem('token')
  },
  watch: {
    '$route' (to, from) {
      this.locale= this.$i18n.locale
      this.token=localStorage.getItem('token')
    }
  },

  methods: {
    switchLocale(lang) {
      localStorage.setItem('locale', lang);
      this.$i18n.locale = lang;
      this.$router.go(this.$router.currentRoute)
    },
    signout(){
      localStorage.setItem('token', '')
      localStorage.setItem('role', '')
      localStorage.setItem('user','')
      this.$router.push('/authorization-manager')
    }
  },
}
</script>
<style scoped>
.manager-profile-button{
  margin-right: 2rem;
  cursor: pointer;
  text-decoration: none;
  width: 50px;
  text-align: center;
  color: white;
  font-size: 20px;
  margin-top: auto;
  margin-bottom: auto;
}
.signout-label{
  line-height: 25.5px;
  text-align: center;
}
.signout-btn{
  border: unset;
  position: relative;
  box-shadow: 0px 0px 13px rgba(52, 63, 104, 0.08);
  width: 166px;
  height: 51px;
  max-height: 51px;
  font-size: 14px;
  line-height: 25.5px;
  padding-right: 20px;
  text-align: center;
  color: #04C2F4;
  margin: auto 0;
  background-color: white;
  border-radius: 10px;
}
.content-end{
  height: 100%;
  display: flex;
  justify-content: end;
}
.navbarManager{
  background: linear-gradient(90deg, #32436D 0%, #02C7FA 100%), #04C2F4;
width: 100%;
  height: 70px;
}
</style>
