import Vue from 'vue'
import VueRouter from 'vue-router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import routes from './routes'

Vue.use(VueRouter)

const router = new VueRouter({
  linkActiveClass: 'active',
  mode: 'history',
  base: '/',
  routes: routes,
  // scrollBehavior (to, from, savedPosition) {
  scrollBehavior (to, from) {
    if (to.hash) {
      setTimeout(() => {
        const element = document.querySelector(to.hash);
        if (element) {
          window.scrollTo({
            top: element.offsetTop,
            behavior: 'smooth'
          });
        }
      }, 500);
    } else {
      return { x: 0, y: 0 }
    }
  }
})

router.beforeEach((to, from, next) => {
  NProgress.start()
  next()
})

router.afterEach(() => {
  NProgress.done()
})

router.beforeEach((to, from, next) => {
  if (!to.meta.requiresAuth) return next()
  if (!localStorage.token) {
    return next({
      path: window.API_ROOT + '/api/login',
      query: { redirect: to.fullPath }
    })
  }
  next()
})

export default router