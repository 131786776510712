<template>
  <div id="app" class="app">
    <router-view name="sidebar"></router-view>
    <router-view name="header"></router-view>
    <transition name="router" mode="out-in">
      <router-view class="view router-view"></router-view>
    </transition>
    <router-view name="footer"></router-view>
    <router-view name="profilefooter"></router-view>
  </div>
</template>

<script>
import Vue from 'vue';
export default {
  name: 'App',
  beforeCreate() {
    if (!localStorage.getItem('locale')) {
      localStorage.setItem('locale', this.$i18n.locale);
    } else {
      this.$i18n.locale = localStorage.getItem('locale');
    }
    Vue.http.headers.common['X-Locale'] = this.$i18n.locale;
  },
  mounted() {
    let script = document.createElement('script');
    switch (this.$i18n.locale) {
      case 'ru':
        script.src = 'https://code.jivosite.com/widget/yNA8MNiynB';
        break;
      case 'kz':
        script.src = 'https://code.jivosite.com/widget/03d35kOHOi';
        break;
    }
    script.async = true;
    document.body.appendChild(script);
  }
}
</script>

<style lang="css">
html,
body {
  height: 100%;
}
.app {
  min-height: 100%;
  overflow: hidden;
}

.router-view {
  transition: all .5s ease;
}
.router-enter, .router-leave {
  opacity: 0;
  transform: translate3d(20px, 0, 0);
}
</style>