<template>
  <footer class="footer pb-0">
    <div class="container mx-auto text-white pb-4">
      <div class="row">
        <div class="col-lg-3 col-md-12">
          <h5>{{ $t('footer.title') }}</h5>
          <ul class="footer_menu">
            <!-- <li>
                <router-link tag="a" to="/">{{ $t('footer.contacts') }}</router-link>
            </li> -->
            <li>
              <router-link tag="a" to="/terms-of-use">
                {{ $t('footer.terms-of-use') }}
              </router-link>
            </li>
            <li>
              <router-link tag="a" to="/privacy-policy">
                {{ $t('footer.privacy-policy') }}
              </router-link>
            </li>
            <li>
              <router-link tag="a" to="/moderation-policy">
                {{ $t('footer.policy-of-moderation') }}
              </router-link>
            </li>

          </ul>
        </div>
        <div class="col-lg-2 col-md-12">
          <h5>{{ $t('footer.projects-title') }}</h5>
          <ul class="footer_menu">
            <li><a href="https://1000bala.elbasyacademy.kz" target="_blank">{{ $t('footer.project-mynbala') }}</a></li>
            <li><a href="https://birgeoqy.elumiti.kz/" target="_blank">{{ $t('footer.project-birge-oqy') }}</a></li>
            <li><a href="https://medal.elbasyacademy.kz/" target="_blank">{{ $t('footer.project-elbasy') }}</a></li>
          </ul>
        </div>
        <div class="col-lg-3 col-md-12 social">
          <h5>{{ $t('footer.we-are-in-social-networks') }}</h5>
          <ul class="social_icons">
            <li class="social_in"><a href="https://www.instagram.com/elbasy_academy/" target="_blank">Instagram</a></li>
            <li class="social_fb"><a href="https://www.facebook.com/elbasyacademy" target="_blank">Facebook</a></li>
            <li class="social_yt "><a href="https://www.youtube.com/channel/UC9nAqSXFXJSJi5qe1xBTNTA" target="_blank">Youtube</a>
            </li>
          </ul>
        </div>
        <div class=" d-none col-lg-4 col-md-12 d-none d-lg-block invisible">
          <h5>{{ $t('footer.install-app') }}</h5>
          <div class="mobile-apps-wrap d-none">
            <a href="#"><img src="/images/google-badge.svg" width="168" alt=""></a>
            <a href="#"><img src="/images/appstore-badge.svg" width="168" alt=""></a>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-bottom">
      <div class="container">
        <p class="mb-0">© {{ date }} {{ $t('footer.elbasy-academy') }}. {{ $t('footer.copyright') }}</p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
  data() {
    return {
      date: new Date().getFullYear()
    }
  }
}
</script>

<style>
.our-app {
  display: none;
}
</style>
