<template>
  <div>
    <div v-bind:class="{ activeMobile: mobileActive }" @click="burgerMenu()" class="burgerMenu">
      <div class="bar1"></div>
      <div class="bar2"></div>
      <div class="bar3"></div>
    </div>
    <div v-show="mobileActive===true" class=" profile-sidebar sideBarMobile">
      <div class="profile">
        <div class="avatar" style="margin-top: 0" v-bind:style="{ 'background-image': 'url(' + avatar + ')' }"></div>
        <div class="username">{{ user.first_name }} {{ user.last_name }}</div>
        <div v-if="user.education" class="role text-center">{{
            (user.education.status == 0) ? $t('sidebar.member') : ''
          }}
        </div>
      </div>
      <div class="profile-sidebar-menu">
        <router-link @click.native="burgerMenu()" tag="a" to="/profile-parent" class="profile"
                     :class="{'active': $route.path === '/profile-parent'}"><span
            @click="burgerMenu()">{{ $t('sidebar.profile') }}</span></router-link>
        <router-link @click.native="burgerMenu()" tag="a" to="/children" class="parent"
                     :class="{'active': $route.path === '/children'}"><span
            @click="burgerMenu()">{{ $t('sidebar.children') }}</span></router-link>
        <router-link @click.native="burgerMenu()" tag="a" to="/settings-parent" class="settings"
                     :class="{'active': $route.path === '/settings-parent'}"><span
            @click="burgerMenu()">{{ $t('sidebar.settings') }}</span></router-link>
        <router-link @click.native="burgerMenu()" tag="a" to="/surveys-parent" class="surveys d-none"
                     :class="{'active': $route.path === '/surveys-parent'}"><span
            @click="burgerMenu()">{{ $t('sidebar.surveys') }}</span></router-link>
      </div>
    </div>
    <div class="sidebar profile-sidebar">
      <div class="profile">
        <!-- <div class="avatar" :style="'background-image: url(' + avatar + ');'"></div> -->
        <div class="avatar" v-bind:style="{ 'background-image': 'url(' + avatar + ')' }"></div>
        <div class="username">{{ user.first_name }} {{ user.last_name }}</div>
        <div v-if="user.education" class="role text-center">{{
            (user.education.status == 0) ? $t('sidebar.member') : ''
          }}
        </div>
      </div>
      <div class="profile-sidebar-menu">
        <!-- <router-link tag="a" to="/cabinet" class="cabinet" :class="{'active': $route.path === '/cabinet'}">{{ $t('sidebar.cabinet') }}</router-link> -->
        <router-link tag="a" to="/profile-parent" class="profile"
                     :class="{'active': $route.path === '/profile-parent'}">{{ $t('sidebar.profile') }}
        </router-link>
        <router-link tag="a" to="/children" class="parent" :class="{'active': $route.path === '/children'}">
          {{ $t('sidebar.children') }}
        </router-link>
        <router-link tag="a" to="/settings-parent" class="settings"
                     :class="{'active': $route.path === '/settings-parent'}">{{ $t('sidebar.settings') }}
        </router-link>
        <router-link tag="a" to="/surveys-parent" class="surveys d-none"
                     :class="{'active': $route.path === '/surveys-parent'}">{{ $t('sidebar.surveys') }}
        </router-link>
      </div>
    </div>
  </div>

</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import Vue from "vue";
import VueMask from "v-mask";

export default {
  name: 'Navbar',
  data() {
    return {
      mobileActive: false,
      avatar: '',
      user: {},
      collapsed: true
    }
  },
  beforeCreate() {
    Vue.use(VueMask);
    this.$http.get(window.API_ROOT + '/api/user/edit')
        .then((res) => {
          this.avatar = window.API_ROOT + '/api/user/avatar?file=' + res.body.data.avatar;

          this.$http.get(this.avatar)
              .then((res) => {

                var file = res.body;
                var reader = new FileReader();
                reader.readAsDataURL(file);
                this.avatar.src = reader.result;

              }).catch((e) => {

          });


        })
        .catch((e) => {

        });
  },
  mounted() {
    if (document.documentElement.clientWidth <= 992) {
      this.mobileActive = false
    }
    if (localStorage.getItem('role') == 'student') {
      this.$router.push(this.$route.query.redirect || '/cabinet')
    }

    this.$http.get(window.API_ROOT + '/api/user/edit')
        .then((res) => {
          this.user = res.data.data;
        })
        .catch((e) => {
          if (e.status === 401) {
            this.$router.push('/')
          }
        });
  },
  methods: {
    burgerMenu() {
      this.mobileActive = !this.mobileActive
    },
    logoutMethod() {
      this.logout()
    },
    clickaway() {
      this.collapsed = true
    },
    ...mapActions({
      'logout': 'logout'
    })
  }
}
</script>
