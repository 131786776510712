<template>
  <div>
    <div v-bind:class="{ activeMobile: mobileActive }" @click="burgerMenu()" class="burgerMenu">
      <div class="bar1"></div>
      <div class="bar2"></div>
      <div class="bar3"></div>
    </div>
    <div v-show="mobileActive===true" class=" profile-sidebar sideBarMobile">
      <div class="profile">
        <div class="avatar" style="margin-top: 0" v-bind:style="{ 'background-image': 'url(' + avatar + ')' }"></div>
        <div class="username">{{ user.first_name }} {{ user.last_name }}</div>
        <div v-if="user.education" class="role text-center">{{
            (user.education.status == 0) ? $t('sidebar.member') : ''
          }}
        </div>
      </div>
      <div class="profile-sidebar-menu">
        <!--        <router-link tag="a" to="/cabinet" class="cabinet" :class="{'active': $route.path === '/cabinet'}"><span @click="burgerMenu()">{{ $t('sidebar.cabinet') }}</span></router-link>-->
        <a @click="burgerMenu('/profile')" class="profile" :class="{'active': $route.path === '/profile'}">
          {{ $t('sidebar.profile') }}</a>
        <a @click="burgerMenu('/parent')" class="parent "
           :class="{'active': $route.path === '/parent'}">{{ $t('sidebar.parent') }}</a>
        <a @click="burgerMenu('/testing')" class="testing"
           :class="{'active': $route.path === '/testing', 'active': $route.path === '/quizzes-list'}">{{
            $t('sidebar.testing')
          }}</a>
        <a @click="burgerMenu('/notes')" class="notes"
           :class="{'active': $route.path === '/notes'}">{{ $t('sidebar.notes') }}</a>
        <a @click="burgerMenu('/my-consultations')" class="notes"
           :class="{'active': $route.path === '/my-consultations'}">{{ $t('sidebar.my-consultations') }}</a>
        <a @click="burgerMenu('/results')" class="results"
           :class="{'active': $route.path === '/results'}">{{ $t('sidebar.results') }}</a>
        <a v-if="newNotificationsIcon==false" @click="burgerMenu('/notification')" class="notifications"
           :class="{'active': $route.path === '/notification'}">{{ $t('sidebar.notifications') }}
        </a>
        <a v-if="newNotificationsIcon==true" @click="burgerMenu('/notification')" class="notifications-new"
           :class="{'active': $route.path === '/notification'}">{{ $t('sidebar.notifications') }}
        </a>
        <a @click="burgerMenu('/settings')" class="settings"
           :class="{'active': $route.path === '/settings'}">{{ $t('sidebar.settings') }}</a>
        <a @click="burgerMenu('/surveys')" class="surveys d-none"
           :class="{'active': $route.path === '/surveys'}">{{ $t('sidebar.surveys') }}</a>
      </div>
    </div>
    <div class="sidebar profile-sidebar">
      <div class="profile">
        <div class="avatar" v-bind:style="{ 'background-image': 'url(' + avatar + ')' }"></div>
        <div class="username">{{ user.first_name }} {{ user.last_name }}</div>
        <div v-if="user.education" class="role text-center">{{
            (user.education.status == 0) ? $t('sidebar.member') : ''
          }}
        </div>
      </div>
      <div class="profile-sidebar-menu">
        <!--       <router-link tag="a" to="/cabinet" class="cabinet" :class="{'active': $route.path === '/cabinet'}">{{ $t('sidebar.cabinet') }}</router-link>-->
        <router-link tag="a" to="/profile" class="profile" :class="{'active': $route.path === '/profile'}">
          {{ $t('sidebar.profile') }}
        </router-link>
        <router-link tag="a" to="/parent" class="parent " :class="{'active': $route.path === '/parent'}">
          {{ $t('sidebar.parent') }}
        </router-link>
        <router-link tag="a" to="/testing" class="testing"
                     :class="{'active': $route.path === '/testing', 'active': $route.path === '/quizzes-list'}">
          {{ $t('sidebar.testing') }}
        </router-link>
        <router-link tag="a" to="/notes" class="notes" :class="{'active': $route.path === '/notes'}">
          {{ $t('sidebar.notes') }}
        </router-link>
        <router-link tag="a" to="/my-consultations" class="notes"
                     :class="{'active': $route.path === '/my-consultations'}">{{ $t('sidebar.my-consultations') }}
        </router-link>
        <router-link tag="a" to="/results" class="results" :class="{'active': $route.path === '/results'}">
          {{ $t('sidebar.results') }}
        </router-link>
        <router-link v-if="newNotificationsIcon==false" tag="a" to="/notification" class="notifications"
                     :class="{'active': $route.path === '/notification'}">{{ $t('sidebar.notifications') }}
        </router-link>
        <router-link v-if="newNotificationsIcon==true" tag="a" to="/notification" class="notifications-new"
                     :class="{'active': $route.path === '/notification'}">{{ $t('sidebar.notifications') }}
        </router-link>
        <router-link tag="a" to="/settings" class="settings" :class="{'active': $route.path === '/settings'}">
          {{ $t('sidebar.settings') }}
        </router-link>
        <router-link tag="a" to="/surveys" class="surveys d-none" :class="{'active': $route.path === '/surveys'}">
          {{ $t('sidebar.surveys') }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import Vue from "vue";
import VueMask from "v-mask";

export default {
  name: 'Navbar',
  data() {
    return {
      newNotificationsIcon: false,
      newNotificationsArray: [],
      newNotifications: false,
      mobileActive: false,
      avatar: '',
      user: {},
      collapsed: true
    }
  },
  beforeCreate() {
  },
  watch: {
    '$route'(to, from) {
      this.getNotification()
    }
  },
  mounted() {
    let res = JSON.parse(localStorage.getItem('user'))
    if (res) {
      if (res.body) {
        if (res.body.data) {
          this.user = res.body.data;
          this.avatar = window.API_ROOT + '/api/user/avatar?file=' + res.body.data.avatar;
        } else {
          this.getUser()
        }
      } else {
        this.getUser()
      }
    } else {
      this.getUser()
    }


    if (!localStorage.getItem('token')) {
      this.$router.push('/')
    }
    Vue.use(VueMask);


    this.$http.get(this.avatar)
        .then((res) => {
          var file = res.body;
          var reader = new FileReader();
          reader.readAsDataURL(file);
          this.avatar.src = reader.result;
        }).catch((e) => {

    });
    this.getNotification()
    this.newNotificationsIcon = this.newNotifications
    if (localStorage.getItem('role') != 'student') {
      this.$router.push('/profile-parent')
    }


    if (document.documentElement.clientWidth <= 992) {
      this.mobileActive = false
    }
    let timerId = setInterval(() => this.getNotification(), 25000)


  },
  methods: {
    getUser() {
      this.$http.get(window.API_ROOT + '/api/user/edit')
          .then((res) => {
            this.user = res.body.data;
            this.avatar = window.API_ROOT + '/api/user/avatar?file=' + res.body.data.avatar;

          }).catch((e) => {
        if (e.status === 401) {
          this.$router.push('/')
        }
        Vue.toastr({
          message: 'Ошибка',
          description: "Ошибка",
          type: 'error'
        });
      });
    },
getNotification()
{
  this.newNotificationsIcon = this.newNotifications
  this.$http.get(window.API_ROOT + '/api/notifications')
      .then((res) => {
        this.newNotifications = false
        for (var i = 0; res.body.data.length > i; i++) {
          this.newNotificationsArray.push(res.body.data[i].readed)
        }
        for (var x = 0; this.newNotificationsArray.length > x; x++) {
          if (this.newNotificationsArray[x] == null) {
            this.newNotifications = true
          }
        }
      }).catch((e) => {
    if (e.status === 401) {
      this.$router.push('/')
    }
    Vue.toastr({
      message: 'Ошибка',
      description: "Ошибка",
      type: 'error'
    });
  });
},
burgerMenu(url)
{
  if (url != false) {
    this.$router.push(url)
  }
  this.mobileActive = !this.mobileActive
}
,
logoutMethod()
{
  this.logout()
}
,
clickaway()
{
  this.collapsed = true
}
,
...
mapActions({
  'logout': 'logout'
})
}
}
</script>
